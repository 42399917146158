var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import axios from "axios";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";
import store from '../store/index';
var router = useRouter();
/**
 * 参考文档 https://github.com/axios/axios
 */
var Request = /** @class */ (function () {
    function Request(params) {
        this.baseURL = null;
        this.cancelTokens = {};
        return this.baseURL = params.baseURL;
    }
    // 获取axios实例的配置
    Request.prototype.getInsideConfig = function () {
        var config = {
            baseURL: this.baseURL,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Authorization": 'Bearer ' + window.localStorage.getItem('token')
            },
            timeout: 50000,
            withCredentials: true,
        };
        return config;
    };
    Request.prototype.interceptors = function (instance) {
        // 请求拦截器
        instance.interceptors.request.use(function (config) {
            // Do something before request is sent
            return config;
        }, function (error) {
            // Do something with request error
            return Promise.reject(error);
        });
        // 响应拦截器
        instance.interceptors.response.use(function (res) {
            if (res.data.code === 401) {
                localStorage.removeItem('token');
                store.commit('setLoginStatus', { status: true });
            }
            switch (res.data.code) {
                case 0:
                case 200:
                    break;
                case 400:
                    ElMessage.warning({ showClose: true, message: res.data.msg });
                    break;
                // 会员
                case 403:
                    break;
                // 身份过期
                case 401:
                    break;
                // 数据不存在
                case 404:
                    break;
                // 未绑定
                case 1011:
                    break;
                default:
                    ElMessage.error({ showClose: true, message: res.data.msg });
                    break;
            }
            return res.data;
        }, function (error) {
            return Promise.reject(error);
        });
    };
    // 创建实例
    Request.prototype.request = function (options, requestKey) {
        var instance = axios.create();
        var newOptions = __assign(__assign({}, this.getInsideConfig()), options);
        // 创建一个取消令牌
        var source = axios.CancelToken.source();
        newOptions.cancelToken = source.token;
        // 存储取消令牌以便于取消请求
        if (requestKey) {
            this.cancelTokens[requestKey] = source.cancel;
        }
        this.interceptors(instance);
        return instance(newOptions);
    };
    // 取消请求方法
    Request.prototype.cancelRequest = function (requestKey) {
        if (this.cancelTokens[requestKey]) {
            this.cancelTokens[requestKey]("请求已取消");
            delete this.cancelTokens[requestKey]; // 移除取消的请求
        }
    };
    Request.prototype.get = function (url, data, config, requestKey) {
        if (config === void 0) { config = {}; }
        var options = Object.assign({
            method: "get",
            url: url,
            params: data,
            header: {
                "Authorization": 'Bearer ' + window.localStorage.getItem('token')
            }
        }, config);
        return this.request(options, requestKey);
    };
    Request.prototype.post = function (url, data, requestKey) {
        return this.request({
            method: "post",
            url: url,
            data: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + window.localStorage.getItem('token')
            }
        }, requestKey);
    };
    Request.prototype.put = function (url, data, requestKey) {
        return this.request({
            method: "put",
            url: url,
            data: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + window.localStorage.getItem('token')
            },
        }, requestKey);
    };
    Request.prototype.delete = function (url, data, requestKey) {
        return this.request({
            method: "delete",
            url: url,
            data: JSON.stringify(data),
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Authorization": 'Bearer ' + window.localStorage.getItem('token')
            }
        }, requestKey);
    };
    return Request;
}());
import config_data from './config';
var request = new Request({
    baseURL: config_data.baseURL,
});
export default request;
