var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { defineComponent as _defineComponent } from 'vue';
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withKeys as _withKeys, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = { class: "login_code" };
var _hoisted_2 = { class: "flexRow-alc lw_back" };
var _hoisted_3 = { class: "flexColumn-alc flex-jcc lw_box" };
var _hoisted_4 = { class: "ft18 ftBold" };
var _hoisted_5 = { class: "lwb_tips i_color" };
var _hoisted_6 = { class: "lwb_code flexRow-alc" };
var _hoisted_7 = {
    key: 0,
    class: "lwb_tips noSelect"
};
import request from '@/api/request';
import { computed, inject, onMounted, onUnmounted, reactive, ref } from 'vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'login_code',
    emits: ['back', 'getCode', 'loginSuccess'],
    setup: function (__props, _a) {
        var _this = this;
        var __emit = _a.emit;
        var emits = __emit;
        onMounted(function () {
            inputFocus();
            startCountdown();
        });
        onUnmounted(function () {
            clearInterval(timer);
        });
        var form = inject('form', {});
        // 验证码
        var codeData = reactive(['', '', '', '']);
        var codeIndex = computed(function () { return codeData.findIndex(function (el) { return !el; }); });
        // 聚焦
        var elInputRef = ref();
        var inputFocus = function () {
            setTimeout(function () {
                var _a;
                (_a = elInputRef.value[codeIndex.value]) === null || _a === void 0 ? void 0 : _a.focus();
            }, 50);
        };
        // 监听文字
        var inputKey = function () {
            inputFocus();
            // 登录
            if (codeIndex.value == -1) {
                sendForm();
            }
        };
        // 键盘
        var keyUpOperate = function (key) {
            if (codeIndex.value > 0) {
                codeData[codeIndex.value - 1] = '';
            }
            inputFocus();
        };
        // 提交表单
        var loading = ref(false);
        var sendForm = function () { return __awaiter(_this, void 0, void 0, function () {
            var codes, res, error_1;
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        loading.value = true;
                        codes = codeData.join('');
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, request.post('/app-api/member/auth/sms-login', {
                                mobile: form.mobile,
                                code: codes
                            })];
                    case 2:
                        res = _c.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _c.sent();
                        return [3 /*break*/, 4];
                    case 4:
                        loading.value = false;
                        // 登录错误
                        if ((res === null || res === void 0 ? void 0 : res.code) !== 0) {
                            codeData.forEach(function (el, ind) { return codeData[ind] = ''; });
                            (_a = elInputRef.value[0]) === null || _a === void 0 ? void 0 : _a.focus();
                            return [2 /*return*/];
                        }
                        // 登录成功
                        localStorage.setItem('token', ((_b = res === null || res === void 0 ? void 0 : res.data) === null || _b === void 0 ? void 0 : _b.refreshToken) || '');
                        emits('loginSuccess', res === null || res === void 0 ? void 0 : res.data);
                        return [2 /*return*/];
                }
            });
        }); };
        var sendStatus = ref('send'); //发送状态 send发送中 nomorl重新发送
        // 获取验证码
        var getCode = function () {
            emits('getCode');
            startCountdown();
        };
        // 倒计时
        var remainingTime = ref(60);
        var timer = null;
        var startCountdown = function () {
            if (timer) {
                clearInterval(timer);
            }
            remainingTime.value = 60;
            sendStatus.value = 'send';
            timer = setInterval(function () {
                if (remainingTime.value > 1) {
                    remainingTime.value--;
                }
                else {
                    sendStatus.value = 'nomorl';
                    clearInterval(timer);
                }
            }, 1000);
        };
        return function (_ctx, _cache) {
            var _component_ArrowLeft = _resolveComponent("ArrowLeft");
            var _component_el_icon = _resolveComponent("el-icon");
            var _component_el_input = _resolveComponent("el-input");
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_el_icon, {
                        class: "clickable",
                        style: { "margin-top": "2px" },
                        onClick: _cache[0] || (_cache[0] = function ($event) { return (_ctx.$emit('back')); })
                    }, {
                        default: _withCtx(function () { return [
                            _createVNode(_component_ArrowLeft)
                        ]; }),
                        _: 1
                    }),
                    _createElementVNode("span", null, "输入 " + _toDisplayString(codeData.length) + " 位验证码", 1)
                ]),
                _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("span", _hoisted_4, "输入 " + _toDisplayString(codeData.length) + " 位验证码", 1),
                    _createElementVNode("span", _hoisted_5, "验证码已发送至 " + _toDisplayString(_unref(form).mobile), 1),
                    _createElementVNode("div", _hoisted_6, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(codeData, function (item, index) {
                            return (_openBlock(), _createElementBlock("div", {
                                key: index,
                                class: _normalizeClass({ lwbc_nomarl: index == codeIndex.value })
                            }, [
                                _createVNode(_component_el_input, {
                                    ref_for: true,
                                    ref_key: "elInputRef",
                                    ref: elInputRef,
                                    modelValue: codeData[index],
                                    "onUpdate:modelValue": function ($event) { return ((codeData[index]) = $event); },
                                    readonly: index != codeIndex.value,
                                    disabled: loading.value,
                                    style: { "width": "48px", "height": "48px", "margin-right": "8px" },
                                    placeholder: "",
                                    onFocus: inputFocus,
                                    onInput: inputKey,
                                    onKeyup: _cache[1] || (_cache[1] = _withKeys(function ($event) { return (keyUpOperate('delete')); }, ["delete", "native"]))
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "readonly", "disabled"])
                            ], 2));
                        }), 128))
                    ]),
                    (loading.value)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_7, "验证中..."))
                        : (_openBlock(), _createElementBlock("span", {
                            key: 1,
                            class: _normalizeClass(["lwb_tips noSelect", sendStatus.value == 'send' ? 'i_color' : 'color clickable']),
                            onClick: _cache[2] || (_cache[2] = function ($event) { return (sendStatus.value == 'nomorl' && getCode()); })
                        }, " 重新发送 " + _toDisplayString(sendStatus.value == 'send' && "".concat(remainingTime.value, "s") || ''), 3))
                ])
            ]));
        };
    }
});
