var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { defineComponent as _defineComponent } from 'vue';
import { vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx } from "vue";
var _hoisted_1 = { class: "login" };
var _hoisted_2 = {
    key: 0,
    class: "mask flexColumn-alc flex-jcc"
};
import { computed, reactive, ref, provide, onUnmounted, onMounted } from 'vue';
import loginPhone from './module/login_phone.vue';
import loginCode from './module/login_code.vue';
import loginWechat from './module/login_wechat.vue';
import loginInfo from './module/login_info.vue';
import { useStore } from 'vuex';
import request from '@/api/request';
import feedback from '@/utils/feedback';
import emitter from '@/utils/eventBus';
export default /*@__PURE__*/ _defineComponent({
    __name: 'login',
    setup: function (__props) {
        var _this = this;
        var store = useStore();
        onMounted(function () {
            emitter.on('eidtUserInfo', function () {
                setTimeout(function () {
                    loginStatus.value = 'info';
                }, 50);
            });
            loginStatus.value = 'phone';
        });
        onUnmounted(function () {
            emitter.off('eidtUserInfo');
        });
        var showMask = computed(function () {
            if (store.state.loginStatus)
                loginStatus.value = 'phone';
            return store.state.loginStatus;
        });
        var closeMask = function () {
            store.commit('setLoginStatus', { status: false });
        };
        var loginStatus = ref('phone'); // 登录状态 phone填写手机号 wechat微信登录 code验证码 info填写用户信息
        var form = reactive({
            mobile: '' // 手机号
        });
        provide('form', form);
        var loading = ref(false);
        var next = function (mobile) { return __awaiter(_this, void 0, void 0, function () {
            var res, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        form.mobile = mobile || '';
                        loading.value = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, getCode()];
                    case 2:
                        res = _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        return [3 /*break*/, 4];
                    case 4:
                        loading.value = false;
                        if (res)
                            return [2 /*return*/];
                        loginStatus.value = 'code';
                        return [2 /*return*/];
                }
            });
        }); };
        // 获取验证码
        var getCode = function () { return __awaiter(_this, void 0, void 0, function () {
            var res, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, request.post('/app-api/member/auth/send-sms-code', {
                                mobile: form.mobile,
                                scene: 1
                            })];
                    case 1:
                        res = _a.sent();
                        if ((res === null || res === void 0 ? void 0 : res.code) == 0)
                            feedback.msgSuccess('验证码已发送');
                        return [3 /*break*/, 3];
                    case 2:
                        error_2 = _a.sent();
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/, (res === null || res === void 0 ? void 0 : res.code) == 0 ? false : true];
                }
            });
        }); };
        // 登录成功
        var loginSuccess = function (res) { return __awaiter(_this, void 0, void 0, function () {
            var error_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, store.dispatch('getUserInfo')];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        error_3 = _a.sent();
                        return [3 /*break*/, 3];
                    case 3:
                        if (res === null || res === void 0 ? void 0 : res.newRegister) {
                            // 新用户
                            loginStatus.value = 'info';
                        }
                        else {
                            store.commit('setLoginStatus', { status: false });
                        }
                        store.commit('setHistroyList');
                        store.commit('setDrawHistroyList');
                        store.commit('setSimpleList');
                        return [2 /*return*/];
                }
            });
        }); };
        // 返回
        var back = function () {
            loginStatus.value = 'phone';
        };
        return function (_ctx, _cache) {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_Transition, { name: "mask-fade" }, {
                    default: _withCtx(function () { return [
                        (showMask.value)
                            ? (_openBlock(), _createElementBlock("section", _hoisted_2, [
                                _withDirectives(_createVNode(loginPhone, {
                                    class: "l_box",
                                    loading: loading.value,
                                    onClose: closeMask,
                                    onNext: next,
                                    onChange: _cache[0] || (_cache[0] = function ($event) { return (loginStatus.value = 'wechat'); })
                                }, null, 8, ["loading"]), [
                                    [_vShow, loginStatus.value === 'phone']
                                ]),
                                (loginStatus.value === 'code')
                                    ? (_openBlock(), _createBlock(loginCode, {
                                        key: 0,
                                        class: "l_box",
                                        onBack: back,
                                        onGetCode: getCode,
                                        onLoginSuccess: loginSuccess
                                    }))
                                    : _createCommentVNode("", true),
                                (loginStatus.value === 'wechat')
                                    ? (_openBlock(), _createBlock(loginWechat, {
                                        key: 1,
                                        class: "l_box",
                                        onBack: back
                                    }))
                                    : _createCommentVNode("", true),
                                (loginStatus.value === 'info')
                                    ? (_openBlock(), _createBlock(loginInfo, {
                                        key: 2,
                                        class: "l_box",
                                        onClose: closeMask
                                    }))
                                    : _createCommentVNode("", true)
                            ]))
                            : _createCommentVNode("", true)
                    ]; }),
                    _: 1
                })
            ]));
        };
    }
});
