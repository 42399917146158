import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import './styles/element.scss';
import 'highlight.js/styles/stackoverflow-light.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import feedback from './utils/feedback';
var app = createApp(App);
for (var _i = 0, _a = Object.entries(ElementPlusIconsVue); _i < _a.length; _i++) {
    var _b = _a[_i], key = _b[0], component = _b[1];
    app.component(key, component);
}
// 全局注册 copyCode 函数
if (typeof window !== 'undefined') {
    window.copyCode = function (event) {
        // 从按钮中获取 `data-code` 属性值
        var datas = event.getAttribute('data-code');
        var code = JSON.parse(decodeURIComponent(datas));
        if (navigator.clipboard) {
            navigator.clipboard.writeText(code).then(function () {
                feedback.msgSuccess('复制成功');
            }).catch(function (err) {
                feedback.msgError('复制失败，尝试手动复制');
            });
        }
        else {
            // 兼容不支持 Clipboard API 的浏览器
            var textarea = document.createElement('textarea');
            textarea.value = code;
            document.body.appendChild(textarea);
            textarea.select();
            document.execCommand('copy');
            document.body.removeChild(textarea);
            feedback.msgSuccess('复制成功');
        }
    };
}
app.use(ElementPlus).use(store).use(router).mount('#app');
