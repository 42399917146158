import { defineComponent as _defineComponent } from 'vue';
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/images/login/qrcode.png';
import _imports_1 from '@/assets/images/logo.png';
var _hoisted_1 = { class: "login_wechat" };
var _hoisted_2 = { class: "flexRow-alc lw_back" };
export default /*@__PURE__*/ _defineComponent({
    __name: 'login_wechat',
    emits: ['back'],
    setup: function (__props) {
        return function (_ctx, _cache) {
            var _component_ArrowLeft = _resolveComponent("ArrowLeft");
            var _component_el_icon = _resolveComponent("el-icon");
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_el_icon, {
                        class: "clickable",
                        style: { "margin-top": "2px" },
                        onClick: _cache[0] || (_cache[0] = function ($event) { return (_ctx.$emit('back')); })
                    }, {
                        default: _withCtx(function () { return [
                            _createVNode(_component_ArrowLeft)
                        ]; }),
                        _: 1
                    }),
                    _cache[1] || (_cache[1] = _createElementVNode("span", null, "微信登录", -1))
                ]),
                _cache[2] || (_cache[2] = _createStaticVNode("<div class=\"flexColumn-alc flex-jcc lw_box\" data-v-67da6efe><span class=\"ft18 ftBold\" data-v-67da6efe>打开微信 APP 扫描二维码登录</span><div class=\"lwb_qrcode\" data-v-67da6efe><img src=\"" + _imports_0 + "\" class=\"lwbq_1\" data-v-67da6efe><img src=\"" + _imports_1 + "\" class=\"lwbq_2\" data-v-67da6efe></div></div>", 1))
            ]));
        };
    }
});
