var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, createVNode as _createVNode } from "vue";
import _imports_0 from '../../../../assets/images/music/back.png';
import _imports_1 from '../../../../assets/images/music/download.png';
import _imports_2 from '../../../../assets/images/music/disc.png';
import _imports_3 from '../../../../assets/images/music/stop.png';
import _imports_4 from '../../../../assets/images/music/play.png';
var _hoisted_1 = { class: "music-player" };
var _hoisted_2 = { class: "main" };
var _hoisted_3 = { class: "flexColumn container" };
var _hoisted_4 = { class: "header flexRow-alc flex-jcsb" };
var _hoisted_5 = { class: "content flexRow flex-jcc flex-als" };
var _hoisted_6 = { class: "cover" };
var _hoisted_7 = ["src"];
var _hoisted_8 = { class: "lyrics-container" };
var _hoisted_9 = { class: "top" };
var _hoisted_10 = { class: "title" };
var _hoisted_11 = { class: "author" };
var _hoisted_12 = {
    key: 1,
    class: "lyrics flexRow-juc"
};
var _hoisted_13 = {
    key: 0,
    class: "progress-bar"
};
var _hoisted_14 = ["src"];
var _hoisted_15 = { class: "audio_right" };
var _hoisted_16 = { class: "bottom" };
var _hoisted_17 = { class: "audio_current" };
var _hoisted_18 = { class: "audio_total" };
import { nextTick, onMounted, onBeforeUnmount, ref, watch } from 'vue';
import { ElMessage } from 'element-plus';
import chat from '../../../../utils/AI/chat';
import emitter from '../../../../utils/eventBus';
export default /*@__PURE__*/ _defineComponent({
    __name: 'music_chat',
    props: {
        sessionId: {},
        songBanner: {},
        songName: {},
        moodName: {},
        genreName: {},
        lyrics: {},
        audioUrl: {},
        detailId: {}
    },
    setup: function (__props) {
        var _this = this;
        var props = __props;
        var info = ref({});
        watch(function () { return props.lyrics; }, function (val) {
            if (!val)
                return;
            info.value = {
                gptDescriptionPrompt: "".concat(props.moodName, "/").concat(props.genreName),
                songBanner: props.songBanner,
                songName: props.songName,
                lyrics: props.lyrics,
                src: props.audioUrl
            };
            nextTick(function () {
                var _a;
                if ((_a = info === null || info === void 0 ? void 0 : info.value) === null || _a === void 0 ? void 0 : _a.src)
                    calculateDuration();
            });
        });
        watch(function () { return props.sessionId; }, function (val) {
            if (props.sessionId)
                musicAI();
        });
        watch(function () { return props.detailId; }, function (val) {
            if (props.detailId)
                getDetail();
        });
        // 获取歌曲
        var checkStatus = ref(null);
        var loading = ref(false);
        var musicAI = function () { return __awaiter(_this, void 0, void 0, function () {
            var endStatus_1, response_1;
            var _this = this;
            return __generator(this, function (_a) {
                if (!props.sessionId)
                    return [2 /*return*/];
                loading.value = true;
                try {
                    endStatus_1 = [20, 30];
                    checkStatus.value = setInterval(function () { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, chat.getMyMusic({
                                        id: props.sessionId
                                    })
                                    // 如果status为0，则停止轮询
                                ];
                                case 1:
                                    response_1 = _a.sent();
                                    // 如果status为0，则停止轮询
                                    if (endStatus_1.includes(response_1.status)) {
                                        clearInterval(checkStatus.value);
                                        loading.value = false;
                                        if (response_1.status == 20) {
                                            info.value = {
                                                gptDescriptionPrompt: response_1.gptDescriptionPrompt,
                                                songBanner: response_1.imageUrl || require('../../../../assets/images/music/disc_default.png'),
                                                songName: response_1.title,
                                                lyrics: response_1.lyric,
                                                src: response_1.audioUrl
                                            };
                                            setTimeout(function () {
                                                nextTick(function () {
                                                    var _a;
                                                    if ((_a = info === null || info === void 0 ? void 0 : info.value) === null || _a === void 0 ? void 0 : _a.src)
                                                        calculateDuration();
                                                });
                                            }, 400);
                                        }
                                        else if (response_1.status == 30) {
                                            return [2 /*return*/, ElMessage.warning('生成失败')];
                                        }
                                    }
                                    return [2 /*return*/];
                            }
                        });
                    }); }, 3000); // 每秒检查一次
                }
                catch (error) { }
                return [2 /*return*/];
            });
        }); };
        // 获取歌曲详情
        var getDetail = function () { return __awaiter(_this, void 0, void 0, function () {
            var res, error_1;
            var _a, _b, _c, _d, _e;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        _f.trys.push([0, 2, , 3]);
                        loading.value = true;
                        return [4 /*yield*/, chat.getSessionDetail(props.detailId)];
                    case 1:
                        res = _f.sent();
                        loading.value = false;
                        if (!res || !res.length)
                            return [2 /*return*/];
                        info.value = {
                            gptDescriptionPrompt: (_a = res[0]) === null || _a === void 0 ? void 0 : _a.gptDescriptionPrompt,
                            songBanner: (_b = res[0]) === null || _b === void 0 ? void 0 : _b.imageUrl,
                            songName: (_c = res[0]) === null || _c === void 0 ? void 0 : _c.title,
                            lyrics: (_d = res[0]) === null || _d === void 0 ? void 0 : _d.lyric,
                            src: (_e = res[0]) === null || _e === void 0 ? void 0 : _e.audioUrl
                        };
                        setTimeout(function () {
                            nextTick(function () {
                                var _a;
                                if ((_a = info === null || info === void 0 ? void 0 : info.value) === null || _a === void 0 ? void 0 : _a.src)
                                    calculateDuration();
                            });
                        }, 400);
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _f.sent();
                        loading.value = false;
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        /**
         * 播放器
         */
        var audioIsPlay = ref(true); //音频是否在播放
        var audioStart = ref('0:00');
        var durationTime = ref('0:00'); //音频的总时长，显示的时间格式
        var duration = ref(0); //音频的总时长
        var audioRef = ref(null);
        var currentProgress = ref(0);
        var handleCloseMusic = function () {
            audioRef.value.pause();
            audioIsPlay.value = true;
        };
        // 获取音频时长
        var calculateDuration = function () {
            var _a;
            var myVid = audioRef.value;
            myVid.loop = false;
            myVid.src = (_a = info.value) === null || _a === void 0 ? void 0 : _a.src;
            // 监听音频播放完毕
            myVid.addEventListener('ended', function () {
                audioIsPlay.value = true;
                currentProgress.value = 0;
            }, false);
            if (myVid != null) {
                myVid.oncanplay = function () {
                    duration.value = myVid.duration; // 计算音频时长
                    durationTime.value = transTime(myVid.duration); //换算成时间格式
                };
                // myVid.volume = 0.8 // 设置默认音量50%
                // 进入页面默认开始播放
                audioRef.value.play();
                audioIsPlay.value = false;
            }
        };
        watch(function () { return duration.value; }, function (val) {
            startAnimationAndScroll();
        });
        // 音频播放时间换算
        var transTime = function (duration) {
            var minutes = Math.floor(duration / 60);
            var seconds = Math.floor(duration % 60);
            var formattedMinutes = String(minutes).padStart(2, '0'); //padStart(2,"0") 使用0填充使字符串长度达到2
            var formattedSeconds = String(seconds).padStart(2, '0');
            return "".concat(formattedMinutes, ":").concat(formattedSeconds);
        };
        // 播放暂停控制
        var playAudio = function () {
            if (audioRef.value.paused) {
                audioRef.value.play();
                audioIsPlay.value = false;
            }
            else {
                audioRef.value.pause();
                audioIsPlay.value = true;
            }
        };
        // 根据当前播放时间，实时更新进度条
        var updateProgress = function (e) {
            var _a;
            var value = e.target.currentTime / e.target.duration;
            if ((_a = audioRef === null || audioRef === void 0 ? void 0 : audioRef.value) === null || _a === void 0 ? void 0 : _a.play) {
                currentProgress.value = value * 100;
                audioStart.value = transTime(audioRef.value.currentTime);
            }
        };
        //调整播放进度
        var handleProgressChange = function (val) {
            if (!val) {
                return;
            }
            var currentTime = duration.value * (val / 100);
            // 更新音频的当前播放时间
            audioRef.value.currentTime = currentTime;
        };
        var coverImgRef = ref(null);
        var lyricsContainerRef = ref(null);
        var scrollLyrics = ref(function () { });
        var startAnimationAndScroll = function () {
            if (!duration.value)
                return;
            if (coverImgRef.value) {
                coverImgRef.value.style.animationPlayState = 'running'; // 开始旋转动画
            }
            if (lyricsContainerRef.value && audioRef.value) {
                var lyricsContainer_1 = lyricsContainerRef.value;
                var totalDuration_1 = audioRef.value.duration || 0;
                var lyricsText_1 = lyricsContainer_1.innerText.split('\n');
                var lyricsCount = lyricsText_1.length;
                // 减慢歌词播放速度，可以通过增加倍数来实现，这里假设减慢2度
                var timePerLine_1 = (totalDuration_1 / lyricsCount) * 1.5;
                var startTime_1 = null;
                scrollLyrics.value = function (timestamp) {
                    if (!startTime_1)
                        startTime_1 = timestamp;
                    var elapsed = timestamp - startTime_1;
                    var currentTime = audioRef.value.currentTime;
                    // 确保 timePerLine 不为 0
                    if (timePerLine_1 === 0) {
                        timePerLine_1 = 1; // 设置一个默认值，或者根据实际情况处理
                    }
                    // 计算当前应该显示的歌词行索引
                    var currentLineIndex = Math.floor(currentTime / timePerLine_1);
                    if (isNaN(currentLineIndex) || !isFinite(currentLineIndex)) {
                        // 处理 NaN 或 Infinity 的情况，例如设置默认值
                        currentLineIndex = 0; // 默认值
                    }
                    // 高亮当前歌词行
                    lyricsText_1.forEach(function (line, index) {
                        var lineElement = lyricsContainer_1.children[index];
                        if (lineElement) {
                            lineElement.classList.toggle('highlight', index === currentLineIndex);
                        }
                    });
                    // 滚动到当前歌词行
                    var currentLineElement = lyricsContainer_1.children[currentLineIndex];
                    if (currentLineElement) {
                        lyricsContainer_1.scrollTop = currentLineElement.offsetTop - lyricsContainer_1.clientHeight / 2 + currentLineElement.clientHeight / 2;
                    }
                    if (currentTime <= totalDuration_1) {
                        requestAnimationFrame(scrollLyrics.value);
                    }
                    else {
                        lyricsContainer_1.scrollTop = 0; // 重置滚动位置
                    }
                };
                // 确保 scrollLyrics.value 是一个函数
                if (typeof scrollLyrics.value === 'function') {
                    requestAnimationFrame(scrollLyrics.value);
                }
            }
        };
        var stopAnimationAndScroll = function () {
            if (coverImgRef.value) {
                coverImgRef.value.style.animationPlayState = 'paused'; // 停止旋转动画
            }
            if (lyricsContainerRef.value && scrollLyrics.value) {
                cancelAnimationFrame(scrollLyrics.value); // 取消当前的动画帧请求
                scrollLyrics.value = function () { }; // 清空动画帧请求的ID
            }
        };
        // 监听音频播放状态
        watch(audioIsPlay, function (isPlaying) {
            if (isPlaying) {
                stopAnimationAndScroll();
            }
            else {
                startAnimationAndScroll();
            }
        });
        // 监听音频播放结束事件
        var handleAudioEnded = function () {
            audioStart.value = '0:00';
            stopAnimationAndScroll();
        };
        // 下载
        var download = function () {
            var link = document.createElement('a');
            link.href = props.audioUrl;
            link.download = "".concat(props.songName, ".mp4"); // 设置下载的文件名
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        };
        // 返回
        var goBack = function () {
            clearInterval(checkStatus.value);
            info.value = {};
            loading.value = false;
            duration.value = 0;
            audioIsPlay.value = false;
            currentProgress.value = 0;
            emitter.emit('closeMusic');
        };
        onMounted(function () { });
        onBeforeUnmount(function () {
            goBack();
            stopAnimationAndScroll();
        });
        return function (_ctx, _cache) {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            var _component_el_skeleton = _resolveComponent("el-skeleton");
            var _component_el_slider = _resolveComponent("el-slider");
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("view", _hoisted_4, [
                            _createElementVNode("img", {
                                src: _imports_0,
                                alt: "",
                                class: "icon",
                                onClick: goBack
                            }),
                            (((_a = info.value) === null || _a === void 0 ? void 0 : _a.src) && !loading.value)
                                ? (_openBlock(), _createElementBlock("img", {
                                    key: 0,
                                    src: _imports_1,
                                    alt: "",
                                    class: "icon",
                                    onClick: download
                                }))
                                : _createCommentVNode("", true)
                        ]),
                        _createElementVNode("view", _hoisted_5, [
                            _createElementVNode("view", _hoisted_6, [
                                _cache[1] || (_cache[1] = _createElementVNode("img", {
                                    src: _imports_2,
                                    alt: "",
                                    class: "disc"
                                }, null, -1)),
                                _createElementVNode("img", {
                                    ref_key: "coverImgRef",
                                    ref: coverImgRef,
                                    src: (_b = info.value) === null || _b === void 0 ? void 0 : _b.songBanner,
                                    alt: "",
                                    class: "cover_img"
                                }, null, 8, _hoisted_7)
                            ]),
                            _createElementVNode("div", _hoisted_8, [
                                (loading.value)
                                    ? (_openBlock(), _createBlock(_component_el_skeleton, {
                                        key: 0,
                                        rows: 10,
                                        animated: ""
                                    }))
                                    : _createCommentVNode("", true),
                                (!loading.value)
                                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                        _createElementVNode("div", _hoisted_9, [
                                            _createElementVNode("div", _hoisted_10, _toDisplayString((_c = info.value) === null || _c === void 0 ? void 0 : _c.songName), 1),
                                            _createElementVNode("div", _hoisted_11, _toDisplayString((_d = info.value) === null || _d === void 0 ? void 0 : _d.gptDescriptionPrompt), 1)
                                        ]),
                                        ((_e = info.value) === null || _e === void 0 ? void 0 : _e.lyrics)
                                            ? (_openBlock(), _createElementBlock("div", {
                                                key: 0,
                                                ref_key: "lyricsContainerRef",
                                                ref: lyricsContainerRef,
                                                class: "lyrics"
                                            }, [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_f = info.value) === null || _f === void 0 ? void 0 : _f.lyrics.split('\n'), function (line, index) {
                                                    return (_openBlock(), _createElementBlock("p", {
                                                        key: index,
                                                        class: "lyrics_text"
                                                    }, _toDisplayString(line), 1));
                                                }), 128)),
                                                _cache[2] || (_cache[2] = _createElementVNode("div", { style: { "height": "300px" } }, null, -1))
                                            ], 512))
                                            : (_openBlock(), _createElementBlock("div", _hoisted_12, _cache[3] || (_cache[3] = [
                                                _createElementVNode("div", { class: "lyrics-tip" }, "暂无歌词", -1)
                                            ])))
                                    ], 64))
                                    : _createCommentVNode("", true),
                                _cache[4] || (_cache[4] = _createElementVNode("div", { class: "mask" }, null, -1))
                            ])
                        ]),
                        (((_g = info.value) === null || _g === void 0 ? void 0 : _g.src) && !loading.value)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                _createElementVNode("audio", {
                                    onTimeupdate: updateProgress,
                                    controls: "",
                                    ref_key: "audioRef",
                                    ref: audioRef,
                                    onEnded: handleAudioEnded,
                                    style: { "display": "none" }
                                }, [
                                    _createElementVNode("source", {
                                        src: (_h = info.value) === null || _h === void 0 ? void 0 : _h.src,
                                        type: "audio/mpeg"
                                    }, null, 8, _hoisted_14),
                                    _cache[5] || (_cache[5] = _createTextVNode(" 您的浏览器不支持音频播放 "))
                                ], 544),
                                _createElementVNode("div", _hoisted_15, [
                                    _createVNode(_component_el_slider, {
                                        class: "slider_box",
                                        modelValue: currentProgress.value,
                                        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) { return ((currentProgress).value = $event); }),
                                        "show-tooltip": false,
                                        onInput: handleProgressChange
                                    }, null, 8, ["modelValue"]),
                                    _createElementVNode("div", _hoisted_16, [
                                        _createElementVNode("div", _hoisted_17, _toDisplayString(audioStart.value), 1),
                                        (!audioIsPlay.value)
                                            ? (_openBlock(), _createElementBlock("img", {
                                                key: 0,
                                                onClick: playAudio,
                                                class: "audio_icon",
                                                src: _imports_3,
                                                alt: "播放"
                                            }))
                                            : _createCommentVNode("", true),
                                        (audioIsPlay.value)
                                            ? (_openBlock(), _createElementBlock("img", {
                                                key: 1,
                                                onClick: playAudio,
                                                class: "audio_icon",
                                                src: _imports_4,
                                                alt: "暂停"
                                            }))
                                            : _createCommentVNode("", true),
                                        _createElementVNode("div", _hoisted_18, _toDisplayString(durationTime.value), 1)
                                    ])
                                ])
                            ]))
                            : _createCommentVNode("", true)
                    ])
                ])
            ]));
        };
    }
});
