import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, resolveComponent as _resolveComponent, withCtx as _withCtx, Transition as _Transition, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, vShow as _vShow, withDirectives as _withDirectives } from "vue";
import _imports_0 from '../../assets/images/collapse_a.svg';
import _imports_1 from '../../assets/images/clear.png';
import _imports_2 from '../../assets/images/call.png';
var _hoisted_1 = { class: "layout flexRow" };
var _hoisted_2 = { class: "sidebar bgImg" };
var _hoisted_3 = { class: "pgFlex flexColumn layout_main" };
var _hoisted_4 = { class: "header flexRow-alc flex-jcsb" };
var _hoisted_5 = {
    key: 0,
    src: _imports_0
};
var _hoisted_6 = { class: "flexRow-alc" };
var _hoisted_7 = { class: "flexRow-alc" };
var _hoisted_8 = {
    key: 1,
    class: "flexRow-alc"
};
var _hoisted_9 = { class: "user flexRow-alc clickable" };
var _hoisted_10 = ["src"];
var _hoisted_11 = { class: "flexColumn user_list" };
var _hoisted_12 = {
    class: "main pgFlex",
    style: { "height": "calc(100% - 40px)" }
};
var _hoisted_13 = { class: "music_play" };
import { onMounted, onUnmounted, computed, ref } from 'vue';
import { useStore } from 'vuex';
import sidebar from './sidebar.vue';
import feedback from '../../utils/feedback';
import request from '../../api/request';
import { useRoute, useRouter } from 'vue-router';
import emitter from '../../utils/eventBus';
import loginView from '../login/login.vue';
import musicChat from '../subview/music/music_chat/music_chat.vue';
import { ElMessage } from 'element-plus';
export default /*@__PURE__*/ _defineComponent({
    __name: 'index',
    setup: function (__props) {
        var route = useRoute();
        var router = useRouter();
        var store = useStore();
        var popoverRef = ref();
        // 显示播放器
        var showPlay = ref(false);
        // 用户信息
        var userInfo = computed(function () { return store.state.userInfo; });
        var change = function (type) {
            var _a;
            if (type == 'outlogin') {
                // 退出登录
                localStorage.removeItem('token');
                store.state.userInfo = {};
                router.replace('/');
            }
            if (type == 'setting') {
                // 设置
                emitter.emit('eidtUserInfo');
                store.commit('setLoginStatus', { status: true });
            }
            (_a = popoverRef.value) === null || _a === void 0 ? void 0 : _a.hide();
        };
        var isCollapse = computed(function () { return store.state.isCollapse; });
        var handleCollapse = function () {
            store.commit('handleCollapse', !isCollapse.value);
        };
        var closeChat = function () {
            feedback
                .confirm('确定清空对话？')
                .then(function (res) {
                if (res === 'confirm') {
                    if ((route === null || route === void 0 ? void 0 : route.query.viewType) == 'drawing') {
                        deleteDrawChat();
                    }
                    else {
                        deleteChat();
                    }
                }
            })
                .catch(function () { });
        };
        // 删除对话
        var deleteChat = function () {
            request
                .delete("/app-api/lt/ai/chat/message/delete-by-conversation-id?conversationId=".concat(route === null || route === void 0 ? void 0 : route.query.chatid), {})
                .then(function (delRes) {
                if ((delRes === null || delRes === void 0 ? void 0 : delRes.code) == 0) {
                    emitter.emit('getHistory');
                }
            })
                .catch(function () { });
        };
        // 删除绘画对话
        var deleteDrawChat = function () {
            request
                .delete("/app-api/lt/ai/image/delete?id=".concat(route === null || route === void 0 ? void 0 : route.query.chatid), {})
                .then(function (delRes) {
                if ((delRes === null || delRes === void 0 ? void 0 : delRes.code) == 0) {
                    emitter.emit('getDrawHistory');
                }
                else {
                    ElMessage.warning(delRes === null || delRes === void 0 ? void 0 : delRes.msg);
                }
            })
                .catch(function () { });
        };
        var goLogin = function () {
            store.commit('setLoginStatus', { status: true });
        };
        var musicChatInfo = ref();
        var playMusic = function (value) {
            showPlay.value = true;
            musicChatInfo.value = value;
        };
        // 开启语音通话
        var openVoicecall = function () {
            if (route.path == '/voicecall') {
                // 通话页面
                emitter.emit('openVoicecall');
            }
            else {
                // 非通话页面
                router.push('/voicecall');
            }
        };
        onMounted(function () {
            // 关闭音乐播放弹窗
            emitter.on('closeMusic', function () {
                showPlay.value = false;
            });
            emitter.on('playMusic', function (e) {
                playMusic(e);
            });
        });
        onUnmounted(function () {
            emitter.off('closeMusic');
            emitter.off('playMusic');
        });
        return function (_ctx, _cache) {
            var _component_Setting = _resolveComponent("Setting");
            var _component_el_icon = _resolveComponent("el-icon");
            var _component_SwitchButton = _resolveComponent("SwitchButton");
            var _component_el_popover = _resolveComponent("el-popover");
            var _component_router_view = _resolveComponent("router-view");
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createVNode(sidebar)
                ]),
                _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("p", {
                            class: "clickable",
                            onClick: handleCollapse
                        }, [
                            (isCollapse.value)
                                ? (_openBlock(), _createElementBlock("img", _hoisted_5))
                                : _createCommentVNode("", true)
                        ]),
                        _createElementVNode("div", _hoisted_6, [
                            (_unref(route).query && _unref(route).query.chatid)
                                ? (_openBlock(), _createElementBlock("p", {
                                    key: 0,
                                    class: "clickable flexRow-alc",
                                    onClick: closeChat
                                }, _cache[2] || (_cache[2] = [
                                    _createElementVNode("img", {
                                        src: _imports_1,
                                        style: { "width": "24px", "margin-right": "20px" }
                                    }, null, -1)
                                ])))
                                : _createCommentVNode("", true),
                            _createElementVNode("div", _hoisted_7, [
                                (!userInfo.value.id)
                                    ? (_openBlock(), _createElementBlock("div", {
                                        key: 0,
                                        class: "login clickable",
                                        onClick: goLogin
                                    }, "登录"))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                        _createElementVNode("img", {
                                            src: _imports_2,
                                            class: "clickable",
                                            style: { "width": "28px", "margin-right": "20px" },
                                            onClick: openVoicecall
                                        }),
                                        _createVNode(_component_el_popover, {
                                            ref_key: "popoverRef",
                                            ref: popoverRef,
                                            "popper-class": "userpopper",
                                            placement: "bottom-end",
                                            title: "",
                                            width: 200,
                                            trigger: "click",
                                            content: ""
                                        }, {
                                            reference: _withCtx(function () {
                                                var _a;
                                                return [
                                                    _createElementVNode("div", _hoisted_9, [
                                                        _createElementVNode("img", {
                                                            src: ((_a = userInfo.value) === null || _a === void 0 ? void 0 : _a.avatar) || 'https://ai-web-1320245968.cos.ap-guangzhou.myqcloud.com/comm%2Favatar_g.png'
                                                        }, null, 8, _hoisted_10)
                                                    ])
                                                ];
                                            }),
                                            default: _withCtx(function () { return [
                                                _createElementVNode("div", _hoisted_11, [
                                                    _createElementVNode("div", {
                                                        class: "flexRow-alc ul_li br clickable",
                                                        onClick: _cache[0] || (_cache[0] = function ($event) { return (change('setting')); })
                                                    }, [
                                                        _createVNode(_component_el_icon, null, {
                                                            default: _withCtx(function () { return [
                                                                _createVNode(_component_Setting)
                                                            ]; }),
                                                            _: 1
                                                        }),
                                                        _cache[3] || (_cache[3] = _createElementVNode("span", null, "设置", -1))
                                                    ]),
                                                    _createElementVNode("div", {
                                                        class: "flexRow-alc ul_li clickable",
                                                        onClick: _cache[1] || (_cache[1] = function ($event) { return (change('outlogin')); })
                                                    }, [
                                                        _createVNode(_component_el_icon, null, {
                                                            default: _withCtx(function () { return [
                                                                _createVNode(_component_SwitchButton)
                                                            ]; }),
                                                            _: 1
                                                        }),
                                                        _cache[4] || (_cache[4] = _createElementVNode("span", null, "退出登录", -1))
                                                    ])
                                                ])
                                            ]; }),
                                            _: 1
                                        }, 512)
                                    ]))
                            ])
                        ])
                    ]),
                    _createElementVNode("div", _hoisted_12, [
                        _createVNode(_Transition, { name: "el-fade-in" }, {
                            default: _withCtx(function () { return [
                                _createVNode(_component_router_view)
                            ]; }),
                            _: 1
                        })
                    ]),
                    _createVNode(_Transition, { name: "el-fade-in-linear" }, {
                        default: _withCtx(function () { return [
                            _withDirectives(_createElementVNode("div", _hoisted_13, [
                                _createVNode(musicChat, _normalizeProps(_guardReactiveProps(musicChatInfo.value)), null, 16)
                            ], 512), [
                                [_vShow, showPlay.value]
                            ])
                        ]; }),
                        _: 1
                    })
                ]),
                _createVNode(loginView)
            ]));
        };
    }
});
